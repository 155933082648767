import { Authentication } from '../authentication.interface';
import { concatMap, forkJoin, from, Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import { EntityDto } from '@models/entities/entity.dto';
import { EntitiesService } from '@app/services/entities/entities.service';
import { map } from 'rxjs/operators';
import { User } from '@models/access/user.model';
import { Role } from '@models/roles/role';
import { Tools } from '@app/services/common/tools';

export class KeycloakImplementation implements Authentication {
  constructor(private _keycloakService: KeycloakService, private _entitiesService: EntitiesService) {}

  public login(): Observable<boolean> {
    return undefined;
  }

  public loadUserProfile(): Observable<User> {
    return forkJoin([from(this._keycloakService.loadUserProfile()), from(this._keycloakService.getToken())]).pipe(
      concatMap(([user, token]: [UserProfile, string]) =>
        this._entitiesService
          .getEntity(user.attributes.entityId[0])
          .pipe(map((entity: EntityDto) => [user, entity, token]))
      ),
      map(([user, entity, token]: [UserProfile, EntityDto, string]): User => {
        const newUser: User = new User(
          user.id,
          user.username,
          user.firstName,
          user.lastName,
          user.email,
          user.attributes.company[0],
          this._keycloakService.getUserRoles(),
          [],
          user.attributes.entityId[0],
          user.attributes.blockAccessOrg[0],
          entity.roles_table as Role[],
          user.attributes.externalEntityID?.[0],
          user.attributes.networkId?.[0]
        );
        Tools.setupSessionMaterial(newUser, token);
        return newUser;
      })
    );
  }

  public isLoggedIn(): Observable<boolean> {
    return from(this._keycloakService.isLoggedIn());
  }

  public logout(): Observable<boolean> {
    return from(this._keycloakService.logout()).pipe(map((): boolean => true));
  }
}

export interface UserProfile extends KeycloakProfile {
  attributes: {
    entityId: string[];
    blockAccessOrg: string[];
    company: string[];
    validFrom: string[];
    validTo: string[];
    networkId?: string[];
    externalEntityID?: string[];
  };
}
