/*!
 * Copyright IBM Corp. All Rights Reserved.
 * Unauthorized copying/modification of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { Injectable } from '@angular/core';
import { Tools } from '../common/tools';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Routing } from '@app/core/enum/routing.enum';
import { environment } from '@env/environment';
import { AccessCreateUserDto, ComplementaryMetaData, CreateUserDto } from '@app/models/access/user.dto';
import { BaseHttpRequestOptionsService } from '../common/base-http-request-options.service';
import { TrimTool } from '../common/trim-tool/trim-tool';
import { Observable } from 'rxjs';
import { AppConfig } from '@app/app.config';
import { IdentityProviderOptions, IdentityProviders } from '@env/environment.model';

@Injectable()
export class UserService {
  private readonly _baseAccessUrl: string = `${environment.accessUrl}api/`;
  private readonly _baseUrl: string = `${environment.octopusUrl}api/`;
  private readonly _identityProviderConfig: IdentityProviderOptions = this._configService.getConfig('identityProvider');

  public constructor(
    private httpClient: HttpClient,
    private baseHttpRequestOptionsService: BaseHttpRequestOptionsService,
    private _configService: AppConfig
  ) {}

  public createUser(user: CreateUserDto): Observable<string | any> {
    // TODO when (if) user creation endpoint is implemented in access idp service,
    //  keep only the `createIdpUser` implementation and rename `createUser`
    if (this._identityProviderConfig.name !== IdentityProviders.KEYCLOAK) {
      return this.createAccessUser(user);
    }
    return this.createIdpUser(user);
  }

  public createIdpUser(user: CreateUserDto): Observable<string> {
    const createUrl: string = `${this._baseUrl}${Routing.IdpUserCreate}`;
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    const trimmedUser: CreateUserDto = TrimTool.applyTrimOnObject<CreateUserDto>(user);

    return this.httpClient.post<any>(createUrl, trimmedUser, header);
  }

  /**
   * @deprecated Once Access idp implementation handles user creation, remove this
   */
  public createAccessUser(user: CreateUserDto): Observable<any> {
    const createUrl: string = `${this._baseAccessUrl}${Routing.AccessUserCreate}`;
    const header: { headers: HttpHeaders } = this.baseHttpRequestOptionsService.create();
    // Remove when user creation is done through the server (and not directly to the identity service)
    header.headers = header.headers.set('x-access-token', Tools.getSessionToken());
    const accessUser: AccessCreateUserDto = {
      userID: user.id,
      userName: user.username,
      userFirstName: user.givenName,
      userLastName: user.familyName,
      userEmail: user.email,
      company: user.company,
      roles: user.roles,
      permissions: user.permissions,
      complementaryMetaData: {
        entity: user.entity,
        blockAccessOrg: user.blockAccessOrg
      },
      password: user.password
    };

    const trimmedUser: AccessCreateUserDto = TrimTool.applyTrimOnObject<AccessCreateUserDto>(accessUser);
    return this.httpClient.post<any>(createUrl, trimmedUser, header);
  }

  public get headers(): HttpHeaders {
    return Tools.createAuthorizationHeader();
  }
}
