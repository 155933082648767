// Copyright Banque de France. All Rights Reserved.
// This file is the property of Banque de France.
// It cannot be copied and/or distributed without the express
// permission of Banque de France.

export enum ScopeType {
  USED = 'used',
  OWNED = 'owned',
  OWNED_CUSTODY = 'owned_custody',
  MANAGED = 'managed',
  MANAGED_CUSTODY = 'managed_custody',
  SUPERVISED = 'supervised',
  NCB_SUPERVISED = 'ncb_supervised',
  MANAGED_NCB_SUPERVISED = 'managed_ncb_supervised',
  CREDIT_PROVIDER = 'credit-provider',
  ISSUED = 'issued',
  PUBLIC = 'public'
}

export enum ScopeDisplay {
  used = 'used',
  owned = 'owned',
  owned_custody = 'owned',
  managed = 'managed',
  managed_custody = 'managed',
  supervised = 'supervised',
  'credit-provider' = 'credit-provider',
  issued = 'issued',
  public = 'public',
  ncb_supervised = 'national supervised'
}
